.timingsInfo-container {
    /* margin-top: 60px; */
    width: 750px;
}

.timingsInfo-container button {
    color: rgba(34, 137, 203, 255);
    background-color: white;
    border: 1px solid rgba(34, 137, 203, 255);
    border-radius: 5px;
    padding: 0px;
    width: 100px; /* Set a fixed width for the button */
    height: 30px; /* Set a fixed height for the button */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any overflow text */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
    display: inline-block; /* Ensure the button is inline-block */
    text-align: center; /* Center the text */
    line-height: 30px;

    /* font-weight: bold; */
}

.timingsInfo-container button:hover {
    color: white;
    background-color: rgba(34, 137, 203, 255);
    font-weight: normal;
    cursor: pointer;
}

.timingsInfo-container input {
    /* border: 1px solid rgba(34, 137, 203, 255); */
    /* border-bottom: 1px solid gray; */
    background: transparent;
    /* margin-top: 30px; */
    /* width: 200px; */
    /* height: 15px; */
    /* font-size: 17px; */
    /* padding: 3px; */
    margin-left: 0%;
    font-size: 15px;
}

.timingsInfo-container input:focus {
    outline: none;
}

.dayTimeChange-container {
    margin-left: 50px;
    font-size: 15px;
}

.dayTimeChange-container p {
    display: inline-block;
    margin-right: 3px;
}

.dayTimeChange-container span {
    color: rgba(34, 137, 203, 255);
    font-size: 15px;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.dayTimeChange-container span:hover {
    text-decoration: none;
}

.dayList-container {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    margin-left: 6%;
    width: 100%;
}

.dayList-subcontainer {
    display: flex;
    gap: 10px;
}

.timingsInfo-container input[type="time"] {
    background: #fff;
    /* min-width: 150px; */
}

.timingsInfo-container .pickuptime-input::before {
    content: '';
    color: #8a8787;
    position: absolute;
    background: #fff;
    width: 150px;
    margin-bottom: 5px;
}

.timingsInfo-container .droptime-input::before {
    content: 'Time';
    color: #8a8787;
    position: absolute;
    background: #fff;
    width: 150px;
    margin-bottom: 5px;
}

.timingsInfo-container .pickuptime-input::before {
    /* content: "Time"; */
}

.timingsInfo-container .droptime-input::before {
    content: "Time";
}

.timingsInfo-container input[type="time"]:focus:before {
    width: 0;
    content: '';
}

.dayList-container span {
    font-size: 12px;
    color: grey;
}

.timingsInfo-container .error {
    margin-top: 5px;
    margin-left: 6%;
    font-size: 13px;
    color: red;
}

.timingsInfo-container footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    gap: 20px;
    margin-bottom: 15px;
}

.timingsInfo-container footer .button {
    padding: 5px 25px;
}

.timingsInfo-container footer .button:nth-child(1) {
    color: gray;
    border: 1px solid gray;
}

.timingsInfo-container footer .button:nth-child(1):hover {
    background-color: white;
    /* color: white; */
    font-weight: bold;
}

.timingsInfo-container footer .button:nth-child(2) {
    color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
}

.timingsInfo-container footer .button:nth-child(2):hover {
    color: white;
    background-color: rgba(42, 149, 69, 255);
}

.dayList-container .selected-time {
    display: inline-block;
    align-items: center;
    border: 1px solid rgba(34, 137, 203, 255);
    /* border-radius: 5px; */
    padding: 0 15px;
    height: 30px;
    background-color: white;
    color: rgba(34, 137, 203, 255);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 30px;
    margin-left: -15px; /* Ensure it aligns perfectly with the button */
    /* align-self: center;  */
}

@media only screen and (max-width: 768px) {
    .dayList-subcontainer {
        flex-direction: column;
    }
    .timingsInfo-container {
        width: 300px;
    }
}