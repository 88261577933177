.editDriverContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 450px;
    height: 300px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10%;
}

.editDriverContainer header {
    height: 40px;
    background-color: rgba(34, 137, 203, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.editDriverContainer main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.editDriverContainer footer {
    align-self: flex-end;
    margin-bottom: 10px;
    margin-right: 7.5%;
}

.editDriverContainer footer button {
    padding: 7px 21px;
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}