/* styles.css */

/* Font size classes */
.font-x-small {
    font-size: 8px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .font-small {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .font-regular {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .font-medium {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .font-large {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .font-x-large {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
  }
  