.edit-profile-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(88, 88, 88, 0.5);
  z-index: 97;
  margin-top: -65px;
  /* height: 100%; */
}

.edit-profile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  min-width: 300px;
  background-color: white;
  height: auto;
  min-height: 200px;
  z-index: 98;
  box-shadow: 0 0 20px rgba(33, 33, 33, 0.3);
  border-radius: 10px;
}

.edit-profile main p {
  font-size: 14px;
}

.edit-profile header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(34, 137, 203, 255);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

.edit-profile .edit-icon {
  position: absolute;
  background-color: rgba(34, 137, 203, 255);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 4px;
  color: white;
  cursor: pointer;
  bottom: 5px;
  right: 5px;
}

.edit-profile header span:nth-child(2) {
  cursor: pointer;
}

.edit-profile main {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin-right: 10px;
  gap: 20px;
}

.edit-profile .admin-info {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.edit-profile .admin-info img {
  border-radius: 50%;
  object-fit: fill;
  width: 80px;
  height: 80px;
}

.edit-profile main .change-password {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.edit-profile main .change-password input {
  border: none;
  border-bottom: 1px solid gray;
  width: 200px;
  font-size: 15px;
}

.edit-profile main .change-password input:focus {
  outline: none;
}

.edit-profile footer {
  margin: 10px 0;
  align-self: flex-end;
}

.edit-profile footer button {
  padding: 6px 20px;
  cursor: pointer;
  color: white;
  background-color: rgba(42, 149, 69, 255);
  border: 1px solid rgba(42, 149, 69, 255);
  border-radius: 5px;
}

.edit-profile footer button:hover {
  background-color: green;
}

@media only screen and (max-width: 768px) {
  .edit-profile main .change-password {
    display: block;
    margin-bottom: 0;
  }

  .edit-profile main .change-password input {
    margin-top: 15px;
  }

  .edit-profile main .change-password input:nth-child(1) {
    margin-top: 0;
  }
}