.table-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
  /* border-radius: 30px; */
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 1px;
}

.pagination .page-num {
  padding: 3px 9px;
  cursor: pointer;
  font-weight: 400;
  color: black;
  font-size: 15px;
}

.pagination .page-num:hover {
  background-color: #86a7ee;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
}

.pagination .active {
  background-color: rgb(18, 116, 155);
  border-radius: 50%;
  color: #fff;
}

.table-container {
  background-color: white;
  border-radius: 10px;
  margin: 0 20px;
  /* width: 100%; */
  /* overflow: auto; */
}

.table-container .header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container .header .filter-buttons button {
  background-color: white;
  border: 1px solid rgba(42, 149, 69, 255);
  color: rgba(42, 149, 69, 255);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
}

.table-container .header .filter-buttons .selected {
  background-color: rgb(50, 139, 50);
  color: white;
}

.table-container .header .filter-buttons button:hover {
  color: white;
  background-color: rgb(50, 139, 50);
}

.active {
  background-color: rgba(42, 149, 69, 255);
  color: white;
}

.datepicker input,
.search-input {
  margin-right: 10px;
  cursor: pointer;
  width: 140px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid rgb(194, 191, 191);
  padding: 0 10px;
}

.datepicker input:focus,
.search-input:focus {
  border: 1px solid black;
  outline: none;
}

.datepicker input::placeholder,
.search-input::placeholder  {
  text-align: center;
}

.export_csv {
  text-decoration: none;
  color: #1D85C6;
  border: 1px solid #1D85C6;
  padding: 5px 13px;
  border-radius: 5px;
  font-size: 12.5px;
  margin-right: 10px;
  cursor: pointer;
}

.export_csv:hover {
  color: white;
  background-color: #1D85C6;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* height: auto; */
  overflow: scroll;
}

.table tr:hover {
  background-color: rgb(247, 245, 245);
}

td {
  text-align: center;
  padding: 8px;
  /* height: 50px; */
  font-size: 14px;
  /* overflow: hidden; */
  /* white-space: nowrap; */
}

th {
  text-align: center;
  padding: 8px;
  height: 40px;
  font-size: 14px;
  width: 10%;
}

th {
  padding: 6px;
  border-top: 1px solid rgb(219, 216, 216);
  background-color: #e1e2e2;
}

tr {
  border-bottom: 1px solid rgb(219, 216, 216);
}

.trips-details {
  transition-property: width;
  transition-duration: 0.7s;
  /* overflow: auto; */
  width: 100%;
}

.table-container .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}

.trips-details .datepicker {
  display: inline-block;
}

.trips-details .filter-buttons {
  display: inline-block;
}

#map {
  position: relative;
  height: 260px;
  width: 100%;
  border-radius: 10px;
}

.trips-details .title {
  padding: 20px 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

#dummy-student-image {
  width: 30px;
  height: 30px;
}

#infowindow-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

@media only screen and (max-width: 768px) {
  .table-container .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .table-container {
    /* width: min-content; */
    overflow: auto;
  }
}
