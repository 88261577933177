.started,
.arrived,
.Alighted,
.Boarding,
.Waiting {
    color: #44B150;
    border: 1px solid #44B150;
    border-radius: 10px;
    width: 50%;
    padding: 5px 0;
    text-align: center;
    text-transform: capitalize;
    margin: auto;
}

.ended {
    color: #D83434;
    border: 1px solid #D83434;
    border-radius: 10px;
    width: 45%;
    padding: 3px 0;
    text-align: center;
    text-transform: capitalize;
    margin: auto;
}

.accepted {
    color: rgba(34, 137, 203, 255);
    border: 1px solid rgba(34, 137, 203, 255);
    border-radius: 10px;
    width: 45%;
    padding: 5px 0;
    text-align: center;
    text-transform: capitalize;
    margin: auto;
}

.tableCellStyle{
    font-family: "Montserrat" !important; 
    font-size: 12px !important;
}