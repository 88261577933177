@import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;500&display=swap');

.privateDriver-addEdit .standard-basic {
    width: 25%;
    margin-left: 2%;
    height: 40px;
}

.privateDriver-addEdit .section-1 {
    position: relative;
}

.driverQrInfo {
    /* border: 3px solid black;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    border-radius: 10px;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    border-image: linear-gradient(rgb(226, 44, 29), rgb(226, 44, 29)), linear-gradient(rgba(245, 174, 48, 255), rgba(245, 174, 48, 255)), linear-gradient(rgba(34, 137, 203, 255), rgba(34, 137, 203, 255)); */
}

.privateDriver-addEdit .section-1 .searchedCorporates {
    position: absolute;
    width: 25%;
    top: 48px;
    left: 2%;
    background-color: white;
    height: 10px;
    /* border: 1px solid black; */
}

.privateDriver-addEdit .searchedCorporates p {
    border: 1px solid gray;
    border-top: none;
    padding: 3px 7px;
    overflow: auto;
}