.corp-reg-container {
    width: 100%;
    height: 100%;
}

.corp-reg-container header {
    height: 20px;
}

.corp-reg-sub-container {
    background-color: white;
    border-radius: 10px;
    margin: 2%;
    padding: 1%;
    width: 96%;
    height: calc(95% - 20px);
}