.menuContainer::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* Track */
.menuContainer::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
}

/* Handle */
.menuContainer::-webkit-scrollbar-thumb {
  background: rgb(175, 174, 174);
}

/* Handle on hover */
.menuContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 
.menuContainer {
  overflow-x: hidden;
  width: 0;
  transition-property: width;
  transition-duration: 0.7s;
  z-index: 99;
  background-color: white;
  /* position: absolute;
  z-index: 10; */
}

.subMenu {
  top: 75px;
  left: 0px;
  min-width: 280px;
  width: 100%;
  height: calc(100vh - 65px);
  /* height: 100vh; */
  background-color: white;
  padding-top: 10px;
  padding-left: 20px;
  overflow-y: auto;
}

.closeIcon {
  text-align: end;
  margin-right: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .menuContainer {
    padding-bottom: 20px;
    overflow-y: hidden;
    /* width: 100%; */
  }
  .subMenu {
    /* height: 25vh; */
    /* overflow-y: scroll; */
    overflow-y: auto;

    width: 100%;
  }
}
