.shift-container {
    /* background-color: white; */
    margin: 0 2%;
    height: 89%;
    border-radius: 10px;
    display: flex;
    gap: 2%;
}

.shift-general-details {
    width: 30%;
    /* padding: 4% 5%; */
    padding: 2%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* gap: 10px; */
}

.standard-basic {
    display: block;
    width: 100%;
    height: 50px;
    margin: 5px;
}

.shift-timing-details {
    width: 68%;
    /* padding: 0 5%; */
    background-color: white;
    height: 100%;
    border-radius: 10px;
}

#shift-map {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.custom-input {
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    outline: none;
    cursor: pointer;
    color: white;
    padding: 7px 15px;
    border-radius: 5px;
}

.shiftDetailsRow {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2%;
    margin-left: 2%;
    /* margin-right: 2%; */
}

.shiftDetailsRow input {
    border: none;
    width: 100px;
    text-align: center;
}

.shiftDetailsRow input:focus {
    outline: none;
}

.jay:focus {
    outline: none;
}

.shiftDetailsHeader div {
    width: 100px;
    text-align: center;
}

.addShiftDetails { 
    border: 1px solid rgb(241, 88, 33); 
    color: rgb(241, 88, 33); 
    background-color: white; 
    cursor: pointer; 
    padding: 5px 10px;
    border-radius: 5px;
    width: 120px;
}

.addShiftDetails:hover {
    background-color: rgb(241, 88, 33);
    color: white;
}

.searchedDriverList {
    background-color: white;
    /* border-bottom: 1px solid black; */
    height: auto;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    top: 95%;
    z-index: 9;
    width: 100%;
}

.searchedDriverList p {
    border: 1px solid gray;
    border-top: none;
    font-size: 14px;
    padding: 5px 5px;
    cursor: pointer;
}

.searchedDriverList p:hover {
    background-color: rgb(240, 239, 239);
}

.shiftTimingsContainer {
    height: auto;
    max-height: 320px;
    overflow: auto;
}

.shiftSaveButton {
    align-self: flex-end;
    margin: 2%;
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
    padding: 7px 15px;
    border-radius: 10px;
    width: 80px;
    cursor: pointer;
}

.shiftSaveButton:hover {
    background-color: green;
}

.loading-gif {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}