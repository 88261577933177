.staff_actions {
    position: absolute;
    left: 60px;
    box-shadow: 0 0 20px rgba(33, 33, 33, .3);
    z-index: 99;
    border-radius: 5px;
    background-color: white;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px
}

.staff_actions p:hover {
    text-decoration: underline;
    cursor: pointer;
    /* font-weight: bold; */
}