.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  height: 10%;
  max-height: 75px;
  padding: 10px;
  background-color: white;
}

.container .menuIcon {
  font-size: 25px;
  cursor: pointer;
}

.container .sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 100px;
  height: 65px;
  object-fit: contain;
}

.orgDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  position: relative;
}

.adminPhoto {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: fill;
  cursor: pointer;
}

img {
  height: 40px;
  width: 40px;
}

.adminPanel {
  position: absolute;
  right: 10px;
  top: 65px;
  width: 220px;
  border: 1px solid white;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  box-shadow: 0 0 20px rgba(33, 33, 33, 0.3);
  z-index: 10;
}

.adminPanel .header {
  background-color: rgba(34, 137, 203, 255);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: -1px;
}

.adminPanel .adminName {
  font-size: 13px;
  padding: 0 20px;
  padding-bottom: 0;
  padding-top: 10px;
}

.adminPanel .adminOrg {
  font-size: 9px;
  padding: 0 20px;
  padding-bottom: 10px;
  margin-top: 0px;
}

.adminPanel .changePassword {
  font-size: 11px;
  padding: 8px;
  padding-left: 20px;
  cursor: pointer;
  height: 30px;
}

.adminPanel .logout {
  cursor: pointer;
  font-size: 11px;
  padding: 8px;
  padding-left: 20px;
  height: 30px;
}

.adminPanel::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(34, 137, 203, 255);
  top: -5px;
  right: 15px;
}

.passwordChangedContainer {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
}

.passwordChangedMessage {
  margin-top: 100px;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(33, 33, 33, 0.3);
}

.successHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  right: 0px;
  top: 0px;
  z-index: 9;
  cursor: default;
}

.passwordText {
  padding: 15px;
}

.passwordOk {
  float: right;
  margin-right: 15px;
  margin-bottom: 10px;
  background-color: rgba(42, 149, 69, 255);
  border: 1px solid rgba(42, 149, 69, 255);
  color: white;
  padding: 5px 15px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .orgDetails {
    gap: 10px;
  }

  .adminPanel {
    margin-right: 0;
  }
}
