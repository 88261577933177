.table-container-routes::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.table-container-routes::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
  /* border-radius: 30px; */
}

/* Handle */
.table-container-routes::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
.table-container-routes::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 1px;
}

.pagination .page-num {
  padding: 3px 9px;
  cursor: pointer;
  font-weight: 400;
  color: black;
  font-size: 15px;
}

.pagination .page-num:hover {
  background-color: #86a7ee;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
}

.pagination .active {
  background-color: rgb(18, 116, 155);
  border-radius: 50%;
  color: #fff;
}

.table-container-routes {
  background-color: white;
  border-radius: 10px;
  margin: 0 20px;
}

.table-container-routes .header {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.table-container-routes .header .filter-buttons button {
  background-color: white;
  border: 1px solid rgba(42, 149, 69, 255);
  color: rgba(42, 149, 69, 255);
  border-radius: 5px;
  padding: 5px 10px;
  /* cursor: pointer; */
  margin-right: 10px;
}

.table-container-routes .header .filter-buttons .selected {
  background-color: rgb(50, 139, 50);
  color: white;
}

.table-container-routes .header .filter-buttons button:hover {
  color: white;
  background-color: rgb(50, 139, 50);
}

.active {
  background-color: rgba(42, 149, 69, 255);
  color: white;
}

.route-search input {
  margin-right: 10px;
  width: 150px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid rgb(194, 191, 191);
  padding: 0 10px;
  transition: width 0.5s;
}

.route-search input:focus {
  width: 300px;
  outline: none;
  border: 1px solid rgba(34, 137, 203, 255);
}

.route-search input::placeholder {
  text-align: center;
}

.export_csv {
  text-decoration: none;
  color: #1D85C6;
  border: 1px solid #1D85C6;
  padding: 5px 13px;
  border-radius: 5px;
  font-size: 12.5px;
  margin-right: 10px;
}

.export_csv:hover {
  color: white;
  background-color: #1D85C6;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* height: auto; */
  overflow: scroll;
}

/* .table tr:hover {
  background-color: rgb(247, 245, 245);
  cursor: pointer;
} */

.routes_records tr:hover {
  /* cursor: pointer; */
  background-color: rgb(247, 245, 245);
}

td {
  text-align: center;
  padding: 8px;
  /* height: 50px; */
  font-size: 14px;
  /* overflow: hidden; */
  /* white-space: nowrap; */
}

th {
  text-align: center;
  padding: 8px;
  height: 40px;
  font-size: 14px;
  width: 10%;
}

th {
  padding: 6px;
  border-top: 1px solid rgb(219, 216, 216);
  background-color: #e1e2e2;
}

.table-container-routes tr {
  border-bottom: 1px solid rgb(219, 216, 216);
  cursor: default;
}

tbody tr {
  height: 50px;
  /* transition: transform 0.2s */
}

tbody tr:hover {
  /* transform: scale(1.02); */
  /* box-shadow: 0 0 20px rgba(33, 33, 33, 0.2); */
  /* box-shadow: 0 -1px 0 4px rgb(100 121 143 / 12%); */
}

.table-container-routes .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}

.trips-details {
  /* position: relative; */
}

.trips-details .route-search {
  display: inline-block;
}

.trips-details .title {
  padding: 20px 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .table-container-routes .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .table-container-routes {
    /* width: min-content; */
    overflow: auto;
  }
}
