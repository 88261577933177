@import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;500&display=swap');

.container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.container::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
  /* border-radius: 30px; */
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  /* width: 100%; */
  width: auto;
  height: 100%;
  /* height: calc(100vh - 75px); */
  margin: 0 25px;
  padding: 10px 0;
  transition-property: width;
  transition-duration: 0.7s;
}

.container .newCorpButton {
  background-color: rgba(34, 137, 203, 255);
  color: white;
  border: 1px solid rgba(34, 137, 203, 255);
  border-radius: 5px;
  padding: 0px 25px;
  cursor: pointer;
  height: 35px;
  align-self: center;
}

.adminName {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: "Poppins";
  font-weight: 500;
}

.adminText {
  font-size: 12px;
  color: gray;
  font-family: "Poppins";
  font-weight: 300;
}

.cards {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-bottom: 25px;
  margin-top: 15px;
  height: 22%;
}

.cards div {
  width: 24%;
  height: 120px;
  border-radius: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 15px;
  padding-bottom: 25px;
  font-size: 14px;
}

.text span {
  font-weight: bold;
  font-size: 18px;
}

.cardContainer {
  display: flex;
  gap: 2%;
  width: 50%;
  height: 100%;
  justify-content: space-between;
  /* style={{ display: "flex", gap: "2%", width: "50%", height: "100%", justifyContent: "space-between" }} */

}

.cards div {
  transition: transform 0.5s;
}

.cards div:nth-child(1) {
  background-color: rgba(34, 137, 203, 255);
}

.cards div:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.cards div:nth-child(2) {
  background-color: rgb(226, 44, 29);
}

.cards div:nth-child(3) {
  background-color: rgba(42, 149, 69, 255);
  /* green */
}

.cards div:nth-child(4) {
  background-color: rgba(245, 174, 48, 255);
  /* yellow change */ 
}

.container header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10%; */
  height: 10%;
  width: 100%;
}

.chart {
  margin: 10px;
  padding-top: 20px;
  margin-bottom: 25px;
  width: 97.5%;
}

.tripChart {
  background-color: white;
  border-radius: 15px;
  width: 97.5%;
  margin-top: -10px;
}

.driverListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 15px;
}

.driverDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
}

.driverInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.driverPhoto {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.viewMoreDriverList {
  font-size: 14px;
  color: rgb(72, 72, 243);
  cursor: pointer;
}

.viewMoreDriverList:hover {
  border-bottom: 1px solid blue;
  color: rgb(24, 24, 156);
}

.driverContainer {
  cursor: pointer;
}

.driverContainer:hover {
  background-color: rgb(212, 201, 201);
}

.driverList {
  background-color: white;
  border-radius: 15px;
  padding: 10px 0px;
  width: 24%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.driverError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.driverErrorMobile {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.driverName {
  font-size: 14px;
}

.carNumber {
  margin-top: -3px;
  font-size: 10.5px;
  color: gray;
}

.activeDriver {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(42, 149, 69, 255);
}

.inActiveDriver {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(226, 44, 29);
}

.mapContainer {
  position: relative;
  width: 74.5%;
}

.map {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.mapText {
  position: absolute;
  top: 0px;
  height: 40px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 20px;
  font-weight: bold;
  opacity: 0.8;
}


.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 30px;
  margin-left: 10px;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: -10px;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 3px;
  position: absolute;
  transition: .4s;
  width: 16px;
}

.first:checked+.slider {
  background-color: rgba(34, 137, 203, 255);
}

.first:checked+.slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.privateDriverInactiveText {
  color: gray;
}

.privateDriverActiveText {
  color: rgba(34, 137, 203, 255);
  font-weight: bold;
}

.cardContainer>div {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  border-radius: 10px;
  height: 100%;
  width: 32.5%;
  display: flex;
}


@media only screen and (max-width: 768px) {
  .container header {
    display: block;
    height: auto;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    width: 100%;
    margin: 10px 0;
  }

  .cardContainer>div {
    width: 100%;
  }

  .container {
    width: auto;
    margin: 0;
    padding: 10px 25px;
  }

  /* .container header {
    padding-right: 10px;
  } */

  .cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .cards div {
    width: 94%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    min-height: 400px;
    max-height: 800px;
  }

  .map {
    width: 100%;
    height: 400px;
  }

  .mapText {
    width: 100%;
  }

  .driverList {
    width: 94%;
    height: 40%;
  }

  .mapContainer {
    width: 94%;
  }
}