.extraKm-container {
  background-color: white;
  border: 1px solid gray;
  width: 90%; /* Adjust width to occupy 90% of the viewport */
  max-width: 680px; /* Limit maximum width to 600px */
  /* min-height: 250px; */
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 99;
  }
  
  .extraKm-subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 35px; */
    justify-content: space-around;
    height: 100%;
    width: 100%;
    padding: 4% 5%;
  }

  .extraKm-container header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(34, 137, 203, 255);
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2.5% 3%;
  }
  
  .extraKm-subContainer main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }
  
  .extraKm-Container footer {
    align-self: flex-end;
  }
  
  .extraKm-subContainer footer button {
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
   .extraKm-subContainer header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .vertical-line{
    border-left: 4px solid rgb(50, 51, 50);
    height: 500px;
    margin-right: 25px;
  }
  .extraKm-subContainer header span {
    font-size: 18px;
    font-weight: bold;
  }
  
  .extraKm-subContainer header svg {
    font-size: 24px;
    cursor: pointer;
  } 
  
  /* .extraKm-subContainer {
    display: flex;
    flex-direction: column;
    height: 100%; 
  }
  
   */
   footer {
    margin-top: auto; 
    display: flex;
    justify-content: flex-end; 
  }
  .form-row {
    margin-bottom: 20px; /* Adjust the margin bottom to create space between rows */
  }
  
  /* Additional styling for specific elements within form rows */
  .form-row .MuiAutocomplete-root,
  .form-row .MuiTextField-root {
    width: 100%; /* Ensure inputs take up full width */
  }
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: auto; /* Center the loader */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media screen and (max-width: 680px) {
    .form-row {
      flex-direction: column; /* Stack form rows on top of each other */
      margin-bottom: 15px; /* Increase margin between stacked rows */
    }
  }
  
  /* Add a media query for screens smaller than 400px (typical mobile width) */
  @media screen and (max-width: 400px) {
    .form-row {
      margin-bottom: 10px; /* Further increase margin for smaller screens */
    }
  }