.bookingDetailsContainer {
    width: 96%;
    height: 95%;
    margin: 2%;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
}

.bookingData {
    border: 1px solid rgb(197, 196, 196);
    width: 100%;
    height: 96%;
    padding: 2%;
}