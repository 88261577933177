.map {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.mapText {
  position: absolute;
  top: 0px;
  height: 40px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 20px;
  font-weight: bold;
  opacity: 0.8;
}

.footer {
  display: flex;
  justify-content: space-between;
  height: 68%;
}

.driverList {
  background-color: white;
  border-radius: 15px;
  padding: 10px 0px;
  width: 24%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.driverListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 15px;
}

.viewMoreDriverList {
  font-size: 14px;
  color: rgb(72, 72, 243);
  cursor: pointer;
}

.viewMoreDriverList:hover {
  border-bottom: 1px solid blue;
  color: rgb(24, 24, 156);
}

.driverError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.driverErrorMobile {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.driverContainer {
  cursor: pointer;
}

.driverContainer:hover {
  background-color: rgb(212, 201, 201);
}

.driverDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
}

.driverInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.driverPhoto {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.driverName {
  font-size: 14px;
}

.carNumber {
  margin-top: -1px;
  font-size: 10.5px;
  color: gray;
}

.activeDriver {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(42, 149, 69, 255);
}

.inActiveDriver {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(226, 44, 29);
}

.mapContainer {
  position: relative;
  width: 74.5%;
}

@media only screen and (max-width: 768px) {
  .container {
    width: auto;
    margin: 0;
    padding: 10px 25px;
  }

  .container header {
    padding-right: 10px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .cards div {
    width: 94%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    min-height: 400px;
    max-height: 800px;
  }

  .map {
    width: 100%;
    height: 400px;
  }

  .mapText {
    width: 100%;
  }

  .driverList {
    width: 94%;
    height: 40%;
  }

  .mapContainer {
    width: 94%;
  }
}