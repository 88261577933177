table .actions {
    background-color: rgba(34, 137, 203, 255);
    border: rgba(34, 137, 203, 255);
    border-radius: 5px;
    padding: 5px 10px;
    /* cursor: pointer; */
    color: white;
}

table .actions:hover {
    background-color: rgb(97, 97, 235);
}

.edit-route {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.edit-route:hover {
    transform: scale(1.1);
}

.background {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}