.stops-container {
    width: 98%;
    height: 98%;
    background-color: white;
    margin: 1%;
    border-radius: 10px;
    display: flex;
    padding: 1%;
    gap: 1%;
}

.stops-container__stopDetails,
.stops-container__inputStops {
    width: 49%;
}

.stops-container__inputStops {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px 0;
}

.stop-input-container {
    display: flex;
    align-items: center;
    /* gap: 20px; */
    justify-content: space-between;
}

.stopInputSearch::after {
    /* content: "X";
    position: absolute;
    right: 10px;
    top: 45%; */
}

#map {
    height: 400px;
}