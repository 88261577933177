.generatePdf-container {
    background-color: white;
    border: 1px solid gray;
    width: 500px;
    /* min-height: 250px; */
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 99;
}

.generatePdf-subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 35px; */
    justify-content: space-around;
    height: 100%;
    width: 100%;
    padding: 4% 8%;
}

.generatePdf-container label {
    display: inline-block;
    /* width: 100px; */
    /* text-align: end; */
}

.generatePdf-container header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(34, 137, 203, 255);
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2.5% 3.5%
}

.generatePdf-container main {
    /* margin-top: 5%; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    /* margin-left: 5%; */
}

.generatePdf-container main input {
    /* border: none;
    border-bottom: 1px solid gray;
    outline: none; */
    /* width: 350px; */
    /* height: 20px;
    padding: 5px;
    margin: 5px 0; */
}

.generatePdf-container footer {
    align-self: flex-end;
}

.generatePdf-container footer button {
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

/* .generatePdf-container .searchedRiders,
.generatePdf-container .searchedDrivers {
    position: absolute;
    width: 300px;
    height: auto;
    max-height: 100px;
    overflow: auto;
    background-color: white;
    border: 1px solid gray;
    border-bottom: none;
    /* top: 0; */
/* margin-left: 100px;
    margin-top: -10px;
    border-top: none; */
/* }  */

.pdfGenerateError {
    width: 100%;
    font-size: 13px;
    background-color: red;
    padding: 8px;
    opacity: 0.7;
    margin-bottom: 20px;
    color: white;
}