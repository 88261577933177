.driverBooking-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    min-height: 320px;
    height: auto;
    width: 550px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  
  .driverBooking-container header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .driverBooking-container header .driverInfo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .driverBooking-container header .driverInfo img {
    border-radius: 50%;
    object-fit: cover;
    width: 55px;
    height: 55px;
  }
  
  .driverBooking-container header .carInfo {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 4px;
  }
  
  .driverBooking-container .driverDetailsInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }
  
  .driverBooking-container main {
    margin: 10px 40px;
  }
  
  .driverBooking-container main .tags {
    display: block;
    outline: none;
    border: none;
    border-bottom: 1px solid grey;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
  }
  
  .driverBooking-container main .dropoffInput {
    display: inline-block;
    outline: none;
    border: none;
    border-bottom: 1px solid grey;
    width: 95%;
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
  }
  
  .addStopSubDiv {
    display: none;
    align-items: center;
    justify-content: space-between;
  }
  
  .addStopDiv {
    width: 20px;
  }
  
  .addStopIconSubDiv {
    display: none;
    flex-direction: column;
    width: 20px;
  }
  
  .removedropInput {
    cursor: pointer;
    font-weight: bold;
  }
  
  .driverBooking-container footer {
    align-self: flex-end;
    margin-right: 40px;
    margin-bottom: 20px;
  }
  
  .driverBooking-container .errorField {
    color: red;
    font-size: 12px;
  }
  
  .driverBooking-container footer button {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
  }
  
  .driverBooking-container footer button:nth-child(1) {
    background-color: rgb(204, 201, 201);
    border: 1px solid rgb(204, 201, 201);
  }
  
  .driverBooking-container footer button:nth-child(2) {
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
  }
  
  .driverBooking-container footer button:nth-child(2):hover {
    background-color: green;
  }
  
  .searchedRiders {
    position: absolute;
    width: 80%;
    height: auto;
    max-height: 100px;
    overflow: auto;
    background-color: white;
    /* border: 1px solid gray; */
    /* top: 0; */
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    margin-top: -10px;
    border-top: none;
    /* border-bottom: none; */
    /* padding: 10px; */
  }
  
  .searchedRiders p {
    border-bottom: 1px solid grey;
    padding: 5px;
    font-size: 13px;
  }
  
  .searchedRiders p:nth-child(last) {
    border: none;
  }
  
  .searchedRiders p:hover {
    cursor: pointer;
    background-color: rgb(230, 225, 225);
  }
  
  .tripRequestError {
    width: 100%;
    font-size: 13px;
    background-color: red;
    padding: 8px;
    opacity: 0.7;
    margin-bottom: 20px;
    color: white;
  }
  
  #myProgress {
    width: 100%;
    background-color: #ddd;
    border-radius: 10px;
    /* margin: 0 0px; */
    position: relative;
    margin-bottom: 20px;
  }
  
  #myBar {
    width: 10%;
    height: 25px;
    background-color: #04aa6d;
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 10px;
    font-size: 13px;
  }
  
  .driverBooking-container header {
    background-color: rgba(34, 137, 203, 255);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
  }
  
  .add-dropoff {
    background-color: rgb(226, 225, 225);
    border: 1px solid grey;
    padding: 3px 6px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pickupDropIcons {
    width: 20px;
    height: 20px;
  }
  
  .wrapper {
    position: relative;
    height: 50px;
    /* background: ; */
  }
  
  .progressbar {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 4px;
  }
  
  .progressbar::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-image: linear-gradient(
      90deg,
      #2289cb 0%,
      #2289cb 25%,
      #e22c1d 25%,
      #e22c1d 50%,
      #2a9545 50%,
      #2a9545 75%,
      #f5ae30 75%,
      #f5ae30 100%
    );
    background-position: 0 0;
    background-repeat: repeat-x;
    animation: movebar 5s linear infinite;
  }
  
  .progressbar::before {
    /* box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1); */
  }
  
  .progressbar::after {
    z-index: 9;
    top: 6px;
    filter: blur(16px);
    opacity: 0.7;
  }
  
  .stylization {
    position: absolute;
    z-index: 999;
    height: 4px;
    width: 90%;
    left: 5%;
    top: 6px;
    opacity: 0.3;
  }
  
  .stylization::before,
  .stylization::after {
    content: "";
    display: block;
    position: absolute;
    height: 4px;
    top: 0;
    border-radius: 2px;
  }
  
  .stylization::before {
    background: #fff;
    left: 0;
    right: 10px;
  }
  
  .stylization::after {
    width: 6px;
    background: #fff;
    right: 0;
  }
  
  @-moz-keyframes movebar {
    from {
      background-position: 0 0;
    }
  
    to {
      background-position: 400px 0;
    }
  }
  
  @-webkit-keyframes movebar {
    from {
      background-position: 0 0;
    }
  
    to {
      background-position: 400px 0;
    }
  }
  
  @-o-keyframes movebar {
    from {
      background-position: 0 0;
    }
  
    to {
      background-position: 400px 0;
    }
  }
  
  @keyframes movebar {
    from {
      background-position: 0 0;
    }
  
    to {
      background-position: 400px 0;
    }
  }
  