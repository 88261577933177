.cancelBookingModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 400px;
    height: 200px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
}

.cancelBookingModal header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgb(226, 44, 29);
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cancelBookingModal main textarea {
    margin-left: 10px;
    padding: 5px;
    outline: none;
    resize: none;
    width: 95%;
    height: 150%;
}

.cancelBookingModal footer {
    align-self: flex-end;
    padding: 10px;
}

.cancelBookingModal footer button {
    width: auto;
    padding: 5px 12px;
    border-radius: 5px;
    color: white;
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    cursor: pointer;
}

.overlay {
    background-color: black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}