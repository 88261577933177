.changePassword_modal_background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 99999;
    display: flex;
    justify-content: center;
    top: 0;
}

.changePassword_modal {
    position: absolute;
    width: 410px;
    height: 330px;
    background-color: white;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 0 0 20px rgba(33, 33, 33, .3);
    top: 100px;
}

.inputForPasswordChange {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
}

.closeIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
}

.input {
    border: none;
    border-bottom: 1px solid gray;
    background: transparent;
    margin-top: 30px;
    width: 350px;
    height: 30px;
    font-size: 17px;
}

.input::placeholder{
    font-size: 15px;
}

.savePassword {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
}

.save {
    background-color: rgba(42, 149, 69, 255);
    border: 1px solid rgba(42, 149, 69, 255);
    color: white;
    padding: 7px 27px;
    cursor: pointer;
}

.save:hover {
    background-color: green;
    border-radius: 15px;
}

.input:focus {
    outline: none;
}

@media only screen and (max-width: 768px) {
    .changePassword_modal {
        width: 280px;
    }
    .input {
        width: 220px;
    }
}