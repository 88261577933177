@import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;500&display=swap');

.backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  right: 0px;
  top: 0px;
  z-index: 9;
  background-color: rgb(88, 88, 88, 0.5);
}

.driverContainer {
  cursor: pointer;
}

.driverContainer:hover {
  background-color: rgb(212, 201, 201);
}

.main-container {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 65px); */
  padding: 15px;
  padding-bottom: 0;
  justify-content: space-between;
  transition-property: width;
  transition-duration: 0.7s;
  height: 100%;
}

.driverlist {
  background-color: white;
  border-radius: 10px;
  width: 24%;
  height: 100%;
  /* height: calc(100vh - 95px); */
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.driverlist .header {
  margin: 15px;
  margin-bottom: 0;
}

.driverlist .header .driver-filter {
  /* display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  gap: 2%; */
}

.driverlist .header .driverType {
  width: 20%;
}

.driverPhoto {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.ontrip,
.online {
  position: absolute;
  right: 0;
  bottom: 3%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(42, 149, 69, 255);
  align-self: center;
  border: 2px solid white;
}

.ontrip {
  background-color: rgba(34, 137, 203, 255);
}

.offline {
  position: absolute;
  right: 0;
  bottom: 3%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(170, 170, 170);
  align-self: center;
  border: 2px solid white;
}

.bookButton {
  border-radius: 5px;
  padding: 2px 4px;
  /* background-color: #444444;
  border: 1px solid #444444; */
  background-color: rgba(42, 149, 69, 255);
  border: 1px solid rgba(42, 149, 69, 255);
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  min-width: 55px;
  font-size: 12px;
  height: 25px;
  font-family: 'Poppins';
  font-weight: 500;
}

.onTripButtton {
  border-radius: 5px;
  padding: 2px 4px;
  background-color: white;
  border: 1px solid rgba(34, 137, 203, 255);
  cursor: pointer;
  /* color: #444444; */
  /* border: none; */
  color: rgba(34, 137, 203, 255);
  font-size: 12px;
  min-width: 55px;
  height: 25px;
  font-family: 'Poppins';
  font-weight: 500;
}

.driverDetails {
  margin-top: 10px;
  position: relative;
  height: 85%;
  overflow: auto;
  padding: 0 15px;
}

.driverDetails .driverSingleData {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(212, 212, 212);
}


.driverDetails .drivername {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 700;
  color: #5A6872;
}

.driverDetails .carnumber {
  font-size: 10px;
  color: grey;
  font-family: 'Poppins';
  font-weight: 300;
  color: #5A6872;
}

.tripType {
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 2px;
  border: 1px solid black;
  border-radius: 5px;
  min-width: 58px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 700;
}

.shuttle {
  color: orange;
  border: 1px solid orange;
}

.private {
  color: rgba(42, 149, 69, 255);
  border: 1px solid rgba(42, 149, 69, 255);
}

.schedule {
  color: rgba(34, 137, 203, 255);
  border: 1px solid rgba(34, 137, 203, 255);
}

.privateDriverMap-container {
  position: relative;
  width: 75%;
  /* height: calc(100vh - 95px); */
  height: 100%;
}

#live-map {
  position: relative;
  height: calc(100% - 40px);
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 10px;
  min-height: 300px;
}

.currentDriver {
  background-color: rgb(228, 222, 222);
}

.driverlist .filter-buttons {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.search {
  width: 100%;
  height: 30px;
  border: 1px solid grey;
  border-radius: 5px;
  padding-left: 6px;
  margin-top: 10px;
}

.search:focus {
  outline: none;
}

.driverlist .filter-buttons button {
  color: white;
  border-radius: 5px;
  width: 75px;
  padding: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.driverlist .filter-buttons button:nth-child(1) {
  background-color: rgba(245, 174, 48, 255);
  border: rgba(245, 174, 48, 255);
}

.driverlist .filter-buttons button:nth-child(2) {
  background-color: rgba(42, 149, 69, 255);
  border: rgba(42, 149, 69, 255);
}

.driverlist .filter-buttons button:nth-child(3) {
  background-color: rgba(34, 137, 203, 255);
  border: rgba(34, 137, 203, 255);
}

.mapText {
  top: 0px;
  /* height: 10%; */
  height: 40px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 20px;
  font-weight: bold;
  opacity: 1.8;
}

.newCorpButton {
  background-color: white;
  color: rgba(34, 137, 203, 255);
  border: 1px solid rgba(34, 137, 203, 255);
  border-radius: 5px;
  padding: 0px 25px;
  cursor: pointer;
  height: 30px;
  align-self: center;
}

.newCorpButton:hover {
  background-color: rgba(34, 137, 203, 255);
  color: white
}

.riderInfoContainer {
  position: absolute;
  background-color: white;
  width: 200px;
  height: 70px;
  top: 50px;
  left: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0px 20px rgba(33, 33, 33, .3);
  border: 1px solid rgba(34, 137, 203, 255);
}

@media only screen and (max-width: 768px) {
  .main-container {
    display: block;
  }

  .driverlist {
    width: 100%;
    margin-bottom: 20px;
  }

  .privateDriverMap-container {
    width: 100%;
  }
}