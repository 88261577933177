.admin-container .admin-details {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.admin-container .admin-details input {
    border: none;
    border-bottom: 1px solid gray;
    width: 180px;
    font-size: 16px;
}

.admin-container .admin-details input:focus {
    outline: none;
}

.admin-container .selectDepartment-text {
    margin-top: 30px;
}